import gql from "graphql-tag";

export const EVALUATE = gql`
  mutation Evaluate($audio: Upload!, $ayaId: ID!) {
    evaluate(audio: $audio, ayaId: $ayaId) {
      ratios
      misPos
      startIndex
      endIndex
      messages
    }
  }
`;
